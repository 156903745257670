import React, { Component } from 'react';
import { Container } from 'reactstrap';
import SidebarNav from './NavMenu';
import TopBar from './topbar';
import { isMobile } from 'react-device-detect';
import authService from './api-authorization/AuthorizeService';
 

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = { export_collapsed: isMobile ? true : false };
        this.getPlaceMenus()
    }



    async getPlaceMenus() {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch('PlaceMenu/GetPlaceMenus', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'get',
            });
            const result = await response.json();
        } catch { 
            //document.getElementById("login_btn").click()
        } 

    }

    render() {
        return (
            <section className="ant-layout ant-layout-has-sider" style={{ backgroundColor: ' rgb(250, 250, 250)', minHeight: '100vh' }}>


                <SidebarNav onSideClose={() => { this.setState({ export_collapsed:  !this.state.export_collapsed }) }} isCollapsed={this.state.export_collapsed } />
                <section className="ant-layout">
                    <TopBar onSideClose={() => { this.setState({ export_collapsed: !this.state.export_collapsed }) }} isCollapsed={this.state.export_collapsed} />
                    {this.props.children}
                </section>

                {/*<SidebarNav /> */}

                {/*<div className="page-wrapper">*/}
                {/*    <TopBar />*/}

                {/*    <div className="page-content">*/}
                {/*        <div className="container-fluid">*/}
                {/*            {this.props.children}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                
                {/*</div>*/}

            </section>
        );
    }
}
