import React, { Component, useState } from 'react';
import { Button, Radio, Typography, PageHeader, Spin, Space, Select, Tag, Modal, Tabs, Col, Tree, Switch, Drawer, Input, Upload, List, Skeleton, message, Row, Image } from 'antd';
import { DeleteOutlined, HolderOutlined, InboxOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import authService from '../../components/api-authorization/AuthorizeService';
import { isMobile } from 'react-device-detect';


import BRow from 'react-bootstrap/Row';
import BCol from 'react-bootstrap/Col';

const { TextArea } = Input;
const { Option } = Select;

export class MainCategories extends Component {

    static displayName = MainCategories.name;

    constructor(props) {
        super(props);
        this.state = { openMenuEdit: false, txtValue: '', loading: false, error: '', listMenu: [], open: false, selectedMenu: '' };
        this.getPlaceMenuCategories();
    }

    success() {
        message.success('Updated Successfuly');
    }
    error() {
        message.error('Failed Update!');
    }
    warning() {
        message.warning('Oops! something went wrong');
    }

    async createDefault() {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/${this.props.match.params.id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'post',
            });
            const result = await response.json();

            if (result) {

                result.data.forEach((f, i) => {
                    f.key = `0-${i}`;
                });

                this.setState({ loading: false, listMenu: result.data, menuName: result.menuName });
                this.success()
            }

        } catch {
            this.setState({ loading: false });
            this.error()

        } finally {
            this.setState({ loading: false });
        }

    }

    async getPlaceMenuCategories() {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/GetPlaceMenuCategories/${this.props.match.params.id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'get',
            });
            const result = await response.json();

            if (result) {


                result.data.forEach((f, i) => {
                    f.key = `0-${i}`;
                });


                this.setState({ loading: false, listMenu: [] });
                this.setState({ loading: false, listMenu: result.data, menuName: result.menuName });
            }

        } catch (ex) {
            console.error(ex)
            this.setState({ loading: false });
        } finally {
            this.setState({ loading: false });
        }

    }

    async ChangeCategoryStatus(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/ChangeCategoryStatus/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenuCategories();
                this.success()

            }

        } catch (ex) {
            this.setState({ loading: false });
            this.error()

        } finally {
            this.setState({ loading: false });
        }

    }

    async EditMenuCategory(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/EditMenuCategory/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify(this.state.selectedMenu)
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenuCategories();
                this.success()
                this.setState({ openMenuEdit: false })
            }

        } catch (ex) {
            this.setState({ loading: false });
            this.error()

        } finally {
            this.setState({ loading: false });
        }

    }

    async DeleteMenu(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/DeleteMenuCategory/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenuCategories();
                this.success()

            }

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()

        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    async DeleteChildImage(url) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/DeleteChildImage`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify({ url: url })
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenuCategories();
                this.success()

            }

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()

        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    async SortCategories(data) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuCategories/SortCategories`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify(data)
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.success()

            }

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()

        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    render() {


        const { Dragger } = Upload;
        const { Title, Text } = Typography;

        const onSelect = (selectedKeys, info) => {
            console.log('selected', selectedKeys, info);
        };


        return (
            <Spin spinning={this.state.loading} delay={0}>

                <PageHeader
                    style={{ backgroundColor: '#fff' }}
                    className="site-page-header"
                    onBack={() => {
                        this.props.history.goBack()
                    }}
                    title={`${this.state.menuName ?? ''} Main Categories`}
                    extra={[
                        <Button onClick={() => this.createDefault()} style={{ borderRadius: '8px' }} type="primary">Create a Category</Button>
                    ]}
                >
                </PageHeader>

                <Tabs defaultActiveKey="1" tabBarStyle={{ padding: '0px 24px', backgroundColor: '#fff' }}>
                    <Tabs.TabPane
                        style={{
                            width: '100%',
                            height: 'calc(100% - 201px)'
                        }}

                        tab="" key="1">


                        <Row>
                            <Col span={isMobile ? 24 : 18} offset={isMobile ? 0 : 3}>
                                <Tree
                                    showIcon={false}
                                    showLine={false}
                                    defaultExpandAll
                                    blockNode
                                    multiple={false}
                                    draggable={{ 'icon': true, }}
                                    height={'100%'}
                                    onDrop={(info) => {

                                        console.log(info);
                                        const dropKey = info.node.key;
                                        const dragKey = info.dragNode.key;
                                        const dropPos = info.node.pos.split('-');
                                        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

                                        const loop = (data, key, callback) => {
                                            for (let i = 0; i < data.length; i++) {
                                                if (data[i].key === key) {
                                                    return callback(data[i], i, data);
                                                }

                                                //if (data[i].children) {
                                                //    loop(data[i].children, key, callback);
                                                //}
                                            }
                                        };

                                        const data = [...this.state.listMenu]; // Find dragObject

                                        let dragObj;
                                        loop(data, dragKey, (item, index, arr) => {
                                            arr.splice(index, 1);
                                            dragObj = item;
                                        });

                                        if (!info.dropToGap) {
                                            // Drop on the content
                                            loop(data, dropKey, (item) => {
                                                item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

                                                item.children.unshift(dragObj);
                                            });
                                        } else if (
                                            (info.node.props.children || []).length > 0 && // Has children
                                            info.node.props.expanded && // Is expanded
                                            dropPosition === 1 // On the bottom gap
                                        ) {
                                            loop(data, dropKey, (item) => {
                                                item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

                                                item.children.unshift(dragObj); // in previous version, we use item.children.push(dragObj) to insert the
                                                // item to the tail of the children
                                            });
                                        } else {
                                            let ar = [];
                                            let i;
                                            loop(data, dropKey, (_item, index, arr) => {
                                                ar = arr;
                                                i = index;
                                            });

                                            if (dropPosition === -1) {
                                                ar.splice(i, 0, dragObj);
                                            } else {
                                                ar.splice(i + 1, 0, dragObj);
                                            }
                                        }
                                        this.SortCategories(data.map((m, i) => ({ id: m.id, index: i })));
                                        this.setState({ listMenu: data })
                                    }}
                                    onSelect={onselect}
                                    treeData={this.state.listMenu}
                                    rootStyle={{
                                        backgroundColor: 'transparent',
                                    }}

                                    titleRender={(item) => (

                                        <BRow key={item.id} style={{ padding: isMobile ? 7 : 15 }}>
                                            <BCol lg="9" xs="6" style={{ alignSelf: 'center' }}>

                                                <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>

                                                    <HolderOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '16px' }} />
                                                    <Image
                                                        width={'40px'}
                                                        height={'40px'}
                                                        style={{ borderRadius: '4px' }} 
                                                        src={item.mainPhotoUrl || 'https://cloudix.tarabezah.com/api/files/Menu/4fd73063-cb54-4ad8-92c3-db4e7d7632fb.png'} />

                                                    <span className="list-title" style={{}} >
                                                        {item.name}
                                                    </span>

                                                </Space>


                                            </BCol>

                                            <BCol lg="3" xs="12" className="d-flex flex-row-reverse" style={{ paddingTop: isMobile ? 10 : 0, justifyContent: isMobile ? 'center' : 'end' }}>
                                                <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
                                                    <Switch checked={item.visible} size="small" style={{}} defaultChecked onChange={(checked) => { this.ChangeCategoryStatus(item.id) }} />

                                                    <SettingOutlined style={{}} onClick={() => {
                                                        this.setState({ selectedMenu: item, openMenuEdit: true })
                                                    }} />


                                                    <DeleteOutlined style={{ color: "red" }} onClick={() => {

                                                        this.setState({ selectedMenu: item, open: true })
                                                    }} />


                                                    <Link className=" ant-btn ant-btn-primary ant-btn-sm" style={{ borderRadius: 8, }} to={{
                                                        pathname: `/menu-index/items/${this.props.match.params.id}/${item.id}`,

                                                    }}>Sections & Items</Link>
                                                </Space>
                                            </BCol>
                                        </BRow>
                                    )}
                                />
                            </Col>
                        </Row>


                        {/*<Row>*/}
                        {/*    <Col>*/}

                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Tabs.TabPane>
                </Tabs>


                <Drawer
                    title={this.state.selectedMenu.name}
                    placement={isMobile ? 'bottom' : 'right'}
                    width={isMobile ? '95%' : 500}
                    height={isMobile ? '70%' : '100%'}

                    onClose={() => { this.setState({ openMenuEdit: false, selectedMenu: {}}) }}
                    open={this.state.openMenuEdit}
                    footer={
                        <Space>
                            <Button onClick={() => {

                                this.setState({ openMenuEdit: false })
                            }}>Cancel</Button>
                            <Button type="primary" onClick={() => {

                                if (!this.state.selectedMenu.name) {
                                    message.warning('Name is required');
                                    return false;
                                }
                                //else if (!this.state.selectedMenu.mainPhotoUrl && !this.state.selectedMenu.mainPhotoUrlNew) {
                                //    message.warning('Image is required is required');
                                //    return false;
                                //} else if (!this.state.selectedMenu.bannerUrl && !this.state.selectedMenu.bannerUrlNew) {
                                //    message.warning('Banner Image is required is required');
                                //    return false;
                                //}



                                this.EditMenuCategory(this.state.selectedMenu.id)
                            }}>
                                OK
                            </Button>
                        </Space>
                    }
                >


                    <Tabs defaultActiveKey="0" tabBarStyle={{ padding: '0px 0px', backgroundColor: '#fff' }}>
                        <Tabs.TabPane
                            style={{
                                padding: '0.0rem',
                                width: '100%',
                                height: 'calc(100% - 201px)'
                            }}

                            tab="" key="0">

                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                <span style={{ color: 'red' }}>* </span>
                                Name  </Title>

                            <TextArea
                                value={this.state.selectedMenu.name}
                                onChange={(e) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.name = e.target.value;

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })




                                }}
                                size='small'
                                autoSize={{
                                    minRows: 1,
                                    maxRows: 1,
                                }}
                            />

                           

                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />




                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                <span style={{ color: 'red' }}>* </span>
                                Menu Image  </Title>

                            <Dragger

                                defaultFileList={
                                    this.state.selectedMenu.mainPhotoUrl ?
                                        [{ url: this.state.selectedMenu.mainPhotoUrl }]
                                        :
                                        undefined
                                }
                                multiple={false}
                                beforeUpload={async file => {
                                    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
                                    if (!isJPG) {
                                        message.error('You can only upload JPG file!');
                                        return Upload.LIST_IGNORE;

                                    }


                                    if (file.size > 1000000) {
                                        message.error('File size must be smaller than 1 mb');
                                        return Upload.LIST_IGNORE;
                                    } else {
                                        var _selected = this.state.selectedMenu;
                                        _selected.mainPhotoUrl = '';

                                        this.setState({ selectedMenu: _selected })

                                        let _base64 = await this.getBase64(file)
                                        this.state.selectedMenu.mainPhotoUrlNew = _base64
                                    }

                                    return false;
                                }}
                                listType='picture' maxCount={1}
                                onRemove={(file) => {
                                    var _selected = this.state.selectedMenu;
                                    _selected.mainPhotoUrl = '';

                                    this.setState({ selectedMenu: _selected })

                                    this.DeleteChildImage(file.url)
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>


                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Banner Image  </Title>

                            <Dragger
                                defaultFileList={
                                    this.state.selectedMenu.bannerUrl ?
                                        [{ url: this.state.selectedMenu.bannerUrl }]
                                        :
                                        undefined
                                }
                                showPreviewIcon={false}
                                beforeUpload={async file => {
                                    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
                                    if (!isJPG) {
                                        message.error('You can only upload JPG file!');
                                        return Upload.LIST_IGNORE;
                                    }

                                    if (file.size > 1000000) {
                                        message.error('File size must be smaller than 1 mb');
                                        return Upload.LIST_IGNORE;
                                    } else {
                                        var _selected = this.state.selectedMenu;
                                        _selected.bannerUrl = '';

                                        this.setState({ selectedMenu: _selected })

                                        let _base64 = await this.getBase64(file)
                                        this.state.selectedMenu.bannerUrlNew = _base64
                                    }

                                    return false;
                                }}
                                onRemove={(file) => {
                                    var _selected = this.state.selectedMenu;
                                    _selected.bannerUrl = '';

                                    this.setState({ selectedMenu: _selected })
                                    this.DeleteChildImage(file.url)
                                }}
                                listType='picture' openFileDialogOnClick={true} maxCount={1}  >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>



                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Display the category  </Title>


                            <Switch

                                checked={this.state.selectedMenu.visible}
                                onChange={(checked) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.visible = checked;

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}

                                style={{ marginTop: 4 }} defaultChecked={false} />

                        </Tabs.TabPane>


                    </Tabs>
                </Drawer>

                <Modal
                    title={`Delete ${this.state.selectedMenu.name}!`}
                    open={this.state.open}
                    onOk={() => {
                        this.DeleteMenu(this.state.selectedMenu.id)
                    }}
                    onCancel={() => {
                        this.setState({ open: false })
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Confirm Delete ? category will be invisible and deleted!</p>
                </Modal>
            </Spin >
        );
    }
}
