import React, { Component, useState } from 'react';
import { Button, message, Typography, Menu, Dropdown, PageHeader, Spin, Space, Select, Tag, Modal, Tabs, Col, Tree, Switch, Drawer, Input, Upload , Skeleton, Row, Image, Form } from 'antd';
import { DeleteOutlined, DownOutlined, HolderOutlined, InboxOutlined, LeftOutlined, RightOutlined, SettingOutlined, UpOutlined } from '@ant-design/icons';
import authService from '../../components/api-authorization/AuthorizeService';
import { isMobile } from 'react-device-detect';
import { TreeNode } from 'antd/lib/tree-select';
import BRow from 'react-bootstrap/Row';
import BCol from 'react-bootstrap/Col';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Label } from 'reactstrap';

const { TextArea } = Input;
const { Option } = Select;

export class MenuItems extends Component {

    static displayName = MenuItems.name;


    constructor(props) {
        super(props);
        this.state = {
            openMenuEdit: false, txtValue: '', loading: false, error: '', listMenu: [], open: false, selectedMenu: '', loadingTree: false, 
        };
        this.GetPlaceMenuItems();


    }

    success() {
        message.success('Updated Successfuly');
    }
    error() {
        message.error('Failed Update!');
    }
    warning() {
        message.warning('Oops! something went wrong');
    }

    async createDefault(parentId, type) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/${this.props.match.params.menuId}/${parentId}/${type}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'post',
                body: JSON.stringify(this.state.selectedMenu)
            });
            const result = await response.json();

            if (result) {
                this.GetPlaceMenuItems();
                this.setState({ loading: false, });
                this.setState({ openMenuEdit: false });
                this.success()
            } else {
                this.error()
            }
        } catch {
            this.setState({ loading: false });
            this.error()
        } finally {
            this.setState({ loading: false });
        }

    }

    async GetPlaceMenuItems(search = '') {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/GetPlaceMenuItems/${this.props.match.params.categoryId}?search=${search}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'get',
            });
            const result = await response.json();

            if (result) {


                result.data.forEach((f, i) => {
                    f.key = `0-${i}`;

                    f.children.forEach((ff, ii) => {
                        ff.key = `0-${i}-${ii}`;
                        //ff.description = RichTextEditor.createEmptyValue()
                    });
                });

                console.log(result)
                this.setState({ loading: false, listMenu: [] });
                this.setState({ loading: false, listMenu: result.data, categoryName: result.categoryName, labels: result.labels });
            }

        } catch (ex) {
            console.error(ex)
            this.setState({ loading: false });
        } finally {
            this.setState({ loading: false });
        }

    }

    async ChangeCategoryStatus(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/ChangeCategoryStatus/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });

                let items = this.state.listMenu;

                items.forEach((f ) => {

                    if (f.id == id) {
                        f.visible = !f.visible
                    }

                    if (f.children) {
                        f.children.forEach(fc => {
                            if (fc.id == id) {
                                 
                                fc.visible = !fc.visible
                            }
                        })
                    }
                })

                this.setState({ listMenu: items })

            }
            this.success();
        } catch (ex) {
            this.setState({ loading: false });
            this.error();
        } finally {
            this.setState({ loading: false });
        }

    }

    async EditMenuItem(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/EditMenuItem/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify(this.state.selectedMenu)
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.GetPlaceMenuItems();
                this.setState({ openMenuEdit: false });
                this.success()
            } else {
                this.error()
            }

        } catch (ex) {
            this.setState({ loading: false });
            this.error()
        } finally {
            setTimeout(() => {
                this.setState({ loading: false });
            },500)
        }

    }

    async EditMenuItemPrice(id, price) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/EditMenuItemPrice/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify({ price: price })
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
            }
            this.success()

        } catch (ex) {
            this.setState({ loading: false });
            this.error()
        } finally {
            this.setState({ loading: false });
        }

    }

    async DeleteMenu(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/DeleteMenuCategory/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.GetPlaceMenuItems();
            }
            this.success()

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()
        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    async DeleteChildImage(url) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/DeleteChildImage`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify({ url: url })
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.GetPlaceMenuItems();
            }
            this.success()

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()
        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    async SortCategories(data) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenuItems/SortCategories`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify(data)
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
            }
            this.success()

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()
        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    onChange = (value) => {
        var _selected = this.state.selectedMenu;
        _selected.description = value;

        this.state.listMenu.forEach(f => {
            if (f.id == _selected.id) {
                f = _selected;
            }
        })

        this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })

        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(
                value.toString('html')
            );
        }
    };

    renderItem(item) {
          return  <BRow key={item.id} style={{ padding: isMobile ? 7 : 7 }}>
                                            <BCol lg="6" xs="6" style={{ alignSelf: 'center' }}>

                                                <Space direction="horizontal" size="small" style={{ display: 'flex' }}>

                                                    <HolderOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '16px' }} />
                                                    <Image
                                                        width={'40px'}
                                                        height={'40px'}
                                                        style={{ borderRadius: '4px' }}
                                                        src={item.mainPhotoUrl || 'https://cloudix.tarabezah.com/api/files/Menu/4fd73063-cb54-4ad8-92c3-db4e7d7632fb.png'} />

                                                    <span className="list-title" style={{}} >
                                                        {item.name}
                                                    </span>

                      {
                          item.childType == 3 && item.soldOut && (
                              <span className="ant-tag ant-tag-magenta" style={{}} >
                                 SOLD OUT
                              </span>
                              )
                      }


                      {
                          item.childType == 3 && item.labels && (JSON.parse(item.labels)).map(m=>
                              <span className="ant-tag ant-tag-volcano" style={{}} >
                                  {m}
                              </span>
                          )
                      }
                                                </Space>


                                            </BCol>

                                            <BCol lg="6" xs="12" className="d-flex " style={{ paddingInlineEnd: 15, paddingTop: isMobile ? 10 : 0, justifyContent: isMobile ? 'center' : 'center' }}>
                                                <Space className="list_item_parent" direction="horizontal" size="middle" style={{ display: 'flex' }}>

                                                    {
                                                        item.childType == 3 && (
                                                            <Input
                                                                onChange={(e) => {
                                                                    item.price = e.target.value + '';

                                                                    this.state.listMenu.forEach(f => {
                                                                        if (f.id == item.id) {
                                                                            f = item;
                                                                        }
                                                                    })

                                                                }}
                                                                bordered className="ant-input-number price_list" style={{ width: 150 }} addonBefore="JOD"
                                                                addonAfter={
                                                                    <Button
                                                                        onClick={() => {

                                                                            this.EditMenuItemPrice(item.id, item.price)
                                                                        }}

                                                                        type="link" size={'small'}>
                                                                        Ok
                                                                    </Button>
                                                                }
                                                                defaultValue={item.price + ''} />)
                                                    }


                                                    <Switch checked={item.visible} size="small" style={{}} defaultChecked onChange={(checked) => { this.ChangeCategoryStatus(item.id) }} />

                                                    <SettingOutlined className="list_item_child"
                                                        style={{}} onClick={() => {
                                                            this.setState({ selectedMenu: {} })
                                                            setTimeout(() => {
                                                                this.setState({ selectedMenu: item, selectedMenuCopy: item, openMenuEdit: true })
                                                            },500)
                                                        }} />

                                                    <DeleteOutlined className="list_item_child" style={{ color: "red" }} onClick={() => {

                                                        this.setState({ selectedMenu: item, open: true })
                                                    }} />

                                                </Space>
                                            </BCol>
                                        </BRow>
          
    }


   

    render() {



        const { Dragger } = Upload;
        const { Title, Text } = Typography;

        const onSelect = (selectedKeys, info) => {
            console.log('selected', selectedKeys, info);
        };
        const tagRender = (props) => {
            const { label, value, closable, onClose } = props;
            const onPreventMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Tag
                    color={'red'}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                        marginRight: 3,
                    }}
                >
                    {label}
                </Tag>
            );
        };

        
        return (
            <Spin spinning={this.state.loading} delay={500}>

                <PageHeader
                    style={{ backgroundColor: '#fff' }}
                    className="site-page-header"
                    onBack={() => {
                        this.props.history.goBack()
                    }}
                    title={`${this.state.categoryName ?? ''} Sub Categories and Items`}

                >
                </PageHeader>

                <Tabs defaultActiveKey="1" tabBarStyle={{ padding: '0px 24px', backgroundColor: '#fff' }}>
                    <Tabs.TabPane
                        style={{
                            width: '100%',
                            height: 'calc(100% - 201px)'
                        }}

                        tab="" key="1">

                        <Row>
                            <Col span={isMobile ? 24 : 18} offset={isMobile ? 0 : 3}>


                                <div class="ant-space ant-space-horizontal ant-space-align-center"
                                    style={{ width: '98%' , backgroundColor: 'white', borderRadius: '10px', padding: '13px', margin: '25px 2px 11px 24px', gap: '16px' }}
                                >

                                    <div className="ant-space-item">
                                        <Dropdown className="Dropdown-style" placement='bottomLeft'
                                            overlayClassName="menu-dropdown-overlay-style"
                                            overlay={(
                                                <div className="menu-dropdown-style">
                                                    <Menu
                                                        onClick={(e) => {
                                                            this.setState({ selectedMenu: {} })

                                                            if (e.key == 'section-ddl')
                                                                setTimeout(() => {
                                                                    this.setState({ openMenuEdit: true, childType: 2, selectedMenu: { childType: 2, isNew: true, price: '0' } })
                                                                }, 500)
                                                            else
                                                                setTimeout(() => {
                                                                    this.setState({ openMenuEdit: true, childType: 3, selectedMenu: { childType: 3, isNew: true, price: '0' } })
                                                                }, 500)
                                                        }}
                                                        items={[
                                                            {
                                                                label: <a onClick={e => e.preventDefault()}>Section</a>,
                                                                key: 'section-ddl',
                                                            },
                                                            {
                                                                label: <a onClick={e => e.preventDefault()}>Item</a>,
                                                                key: 'item-ddl',
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            )} trigger={['click']}>
                                            <a onClick={e => e.preventDefault()}>
                                                <span className="list-title" style={{}} >
                                                    + Add
                                                </span>

                                            </a>
                                        </Dropdown>


                                    </div>
                                    <div className="ant-space-item" style={{width : '100%'}}>
                                        <Input placeholder="Basic usage"

                                            onChange={(e) => {

                                                setTimeout(() => {
                                                    this.setState({ search: e.target.value })
                                                },500)


                                                this.GetPlaceMenuItems(e.target.value)

                                            }}

                                            style={{ width: '100%', borderRadius: 12 }} />


                                    </div>


                                </div>
                                 
                            </Col>
                        </Row>

                        <Row>
                            <Col span={isMobile ? 24 : 18} offset={isMobile ? 0 : 3}>
                                <Tree
                                    showIcon={false}
                                    showLine={false}
                                    defaultExpandAll
                                     
                                    draggable={{ 'icon': true, }}
                                    height={'100%'}
                                    onDrop={(info) => {
                                        debugger
                                        if (this.state.search?.length > 0)
                                            return;

                                        if (info.dragNode.childType != info.node.childType) {
                                            return;
                                        }

                                        if (!info.node.dragOverGapBottom) {
                                            return;
                                        }


                                        const dropKey = info.node.key;
                                        const dragKey = info.dragNode.key;
                                        const dropPos = info.node.pos.split('-');
                                        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

                                        const loop = (data, key, callback) => {
                                            for (let i = 0; i < data.length; i++) {
                                                 
                                                if (data[i].key === key ) {
                                                    return callback(data[i], i, data);
                                                }

                                                if (data[i].children) {
                                                    loop(data[i].children, key, callback);
                                                }
                                            }
                                        };

                                        const data = [...this.state.listMenu]; // Find dragObject

                                        let dragObj;
                                        loop(data, dragKey, (item, index, arr) => {
                                            arr.splice(index, 1);
                                            dragObj = item;
                                        });

                                        if (!info.dropToGap) {
                                            // Drop on the content
                                            loop(data, dropKey, (item) => {
                                                item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

                                                item.children.unshift(dragObj);
                                            });
                                        } else if (
                                            (info.node.props.children || []).length > 0 && // Has children
                                            info.node.props.expanded && // Is expanded
                                            dropPosition === 1 // On the bottom gap
                                        ) {
                                            loop(data, dropKey, (item) => {
                                                item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

                                                item.children.unshift(dragObj); // in previous version, we use item.children.push(dragObj) to insert the
                                                // item to the tail of the children
                                            });
                                        } else {
                                            let ar = [];
                                            let i;
                                            loop(data, dropKey, (_item, index, arr) => {
                                                ar = arr;
                                                i = index;
                                            });

                                            if (dropPosition === -1) {
                                                ar.splice(i, 0, dragObj);
                                            } else {
                                                ar.splice(i + 1, 0, dragObj);
                                            }
                                        }

                                        var newSort = [];
                                        data.forEach((p, pi) => {
                                            newSort.push({ id: p.id, index: pi })
                                            p.children.forEach((c, ci) => {
                                                if (!newSort[pi].children) {
                                                    newSort[pi].children = [];
                                                }
                                                newSort[pi].children.push({ id: c.id, index: ci })
                                            })
                                        });
                                        this.SortCategories(newSort);
                                        this.setState({ listMenu: data })
                                    }}
                                    onSelect={onselect}
                                    treeData={this.state.listMenu}
                                    //treeData={this.state.search ? (this.state.listMenu.filter(f => (f.name.indexOf(this.state.search) > -1) || (f.children.some(s => s.name.indexOf(this.state.search) > -1)))) : this.state.listMenu}
                                    rootStyle={{
                                        backgroundColor: 'transparent',
                                    }}
                                    
                                    
                                    switcherIcon={<DownOutlined style={{ fontSize: 16 }} />}
                                    titleRender={(item) => (

                                        this.renderItem(item)


                                    )}
                                />
                            </Col>
                        </Row>



                    </Tabs.TabPane>
                </Tabs>


                <Drawer
                    title={this.state.selectedMenu.name}
                    placement={isMobile ? 'bottom' : 'right'}
                    width={isMobile ? '95%' : 500}
                    height={isMobile ? '70%' : '100%'}

                    onClose={() => { this.setState({ openMenuEdit: false, selectedMenu: {} }) }}
                    open={this.state.openMenuEdit}
                    footer={
                        <Space>
                            <Button onClick={() => {

                                let _all = this.state.listMenu;
                                let _selcted = this.state.selectedMenuCopy;

                                _all.forEach(f => {
                                    if (f.id == _selcted.id) {
                                        f = this.state.selectedMenuCopy;
                                    }

                                    f.children.forEach(fc => {
                                        if (fc.id == _selcted.id) {
                                            fc = this.state.selectedMenuCopy;
                                        }
                                    })
                                })



                                 

                                this.setState({ openMenuEdit: false, listMenu: JSON.parse(JSON.stringify(_all)) })
                            }}>Cancel</Button>
                            <Button
                                disabled={this.state.loading}
                                type="primary" onClick={() => {

                                if (!this.state.selectedMenu.name) {
                                    message.warning('Name is required');
                                    return false;
                                }
                                //else if (!this.state.selectedMenu.description) {
                                //    message.warning('Description is required');
                                //    return false;
                                //} else if (!this.state.selectedMenu.mainPhotoUrl && !this.state.selectedMenu.mainPhotoUrlNew) {
                                //    message.warning('Image is required is required');
                                //    return false;
                                //} 

                                if (this.state.childType == 3) {
                                    if (!this.state.selectedMenu.parentId) {
                                        message.warning('Section is required');
                                        return false;
                                    } 
                                }



                                if (this.state.selectedMenu.isNew) {
                                    this.createDefault(this.props.match.params.categoryId, this.state.childType)
                                } else {
                                    this.EditMenuItem(this.state.selectedMenu.id)
                                }
                            }}>
                                OK
                            </Button>
                        </Space>
                    }
                >


                    <Tabs defaultActiveKey="0" tabBarStyle={{ padding: '0px 0px', backgroundColor: '#fff' }}>
                        <Tabs.TabPane
                            style={{
                                padding: '0.0rem',
                                width: '100%',
                                height: 'calc(100% - 201px)'
                            }}

                            tab="" key="0">

                            <Form
                                layout="vertical"
                                onValuesChange={() => { }}
                                requiredMark={true}
                            >

                                <Title level={5} style={{ fontSize: 'smaller' }}>

                                    <span style={{ color: 'red' }}>* </span>
                                    Name  </Title>

                                <TextArea
                                    value={this.state.selectedMenu.name}
                                    onChange={(e) => {

                                        var _selected = this.state.selectedMenu;
                                        _selected.name = e.target.value;

                                        this.state.listMenu.forEach(f => {
                                            if (f.id == _selected.id) {
                                                f = _selected;
                                            }
                                        })

                                        this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                    }}
                                    size='small'
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 1,
                                    }}
                                />

                                <div
                                    style={{
                                        margin: '24px 0',
                                    }}
                                />


                                <Title level={5} style={{ fontSize: 'smaller' }}>

                                    {/*<span style={{ color: 'red' }}>* </span>*/}
                                    Description  </Title>


                                {
                                    this.state.selectedMenu.childType == 3 && (
                                        <ReactQuill theme="snow"

                                            value={this.state.selectedMenu.description}
                                            onChange={(e) => {
                                                this.state.selectedMenu.description = e;
                               
                                            }} />
                                        )
                                }

                                {
                                    this.state.selectedMenu.childType == 2 && (

                                        <TextArea
                                            value={this.state.selectedMenu.description}
                                            onChange={(e) => {

                                                var _selected = this.state.selectedMenu;
                                                _selected.description = e.target.value;

                                                this.state.listMenu.forEach(f => {
                                                    if (f.id == _selected.id) {
                                                        f = _selected;
                                                    }
                                                })

                                                this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                            }}
                                            size='small'
                                            autoSize={{
                                                minRows: 3,
                                                maxRows: 5,
                                            }}
                                        />
                                        )
                                }

                            

                                <div
                                    style={{
                                        margin: '24px 0',
                                    }}
                                />


                                <Title level={5} style={{ fontSize: 'smaller' }}>

                                    <span style={{ color: 'red' }}>* </span>
                                    Image  </Title>

                                <Dragger

                                    defaultFileList={
                                        this.state.selectedMenu.mainPhotoUrl ?
                                            [{ url: this.state.selectedMenu.mainPhotoUrl }]
                                            :
                                            undefined
                                    }
                                    multiple={false}
                                    beforeUpload={async file => {
                                        const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
                                        if (!isJPG) {
                                            message.error('You can only upload JPG file!');
                                            return Upload.LIST_IGNORE;

                                        }

                                        if (file.size > 1000000) {
                                            message.error('File size must be smaller than 1 mb');
                                            return Upload.LIST_IGNORE;
                                        } else {
                                            var _selected = this.state.selectedMenu;
                                            _selected.mainPhotoUrl = '';

                                            this.setState({ selectedMenu: _selected })

                                            let _base64 = await this.getBase64(file)
                                            this.state.selectedMenu.mainPhotoUrlNew = _base64
                                        }

                                        return false;
                                    }}
                                    listType='picture' maxCount={1}
                                    onRemove={(file) => {
                                        var _selected = this.state.selectedMenu;
                                        _selected.mainPhotoUrl = '';

                                        this.setState({ selectedMenu: _selected })

                                        this.DeleteChildImage(file.url)
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>


                                {
                                    this.state.listMenu && this.state.selectedMenu.childType == 3 && (
                                        <div>

                                            <div
                                                style={{
                                                    margin: '24px 0',
                                                }}
                                            />

                                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                                <span style={{ color: 'red' }}>* </span>
                                                Section  </Title>
                                            <Select
                                                showSearch
                                                showArrow
                                                defaultValue={this.state.selectedMenu.parentId ? this.state.listMenu.filter(f => f.id == this.state.selectedMenu.parentId)[0]?.name : ''}

                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={(value, opt) => {
                                                    this.state.selectedMenu.parentId = opt.key
                                                }}
                                                options={this.state.listMenu.map(m => new Object({ value: m.name, key: m.id }))}
                                            />

                                        </div>
                                    )
                                }

                                <div
                                    style={{
                                        margin: '24px 0',
                                    }}
                                />


                                {
                                    this.state.selectedMenu.childType == 3 && (
                                        <div>

                                            <div
                                                style={{
                                                    margin: '24px 0',
                                                }}
                                            />

                                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                                {/*<span style={{ color: 'red' }}>* </span>*/}
                                                Labels  </Title>
                                            <Select
                                                mode="multiple"
                                                showArrow
                                                tagRender={tagRender}
                                                defaultValue={this.state.selectedMenu.labels ? (JSON.parse(this.state.selectedMenu.labels)) : []}
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={(value) => {
                                                    this.state.selectedMenu.labels = JSON.stringify(value)
                                                }}
                                                options={this.state.labels}
                                            />

                                        </div>
                                    )
                                }

                                <div
                                    style={{
                                        margin: '24px 0',
                                    }}
                                />





                                {
                                    this.state.selectedMenu.childType == 3 && (
                                        <Form.Item label={'Price'}>
                                            <Input bordered className="ant-input-number " style={{ width: '100%',   }} addonBefore="JOD"
                                                 
                                                defaultValue={this.state.selectedMenu.price + ''}
                                                onChange={(e) => {

                                                    var _selected = this.state.selectedMenu;
                                                    _selected.price = e.target.value;

                                                    this.setState({ selectedMenu: _selected,  })
                                                }}

                                            />
                                        </Form.Item>
                                    )
                                }



                                <div
                                    style={{
                                        margin: '24px 0',
                                    }}
                                />



                                {
                                    this.state.selectedMenu.childType == 3 && (
                                        <Form.Item label={'Days Discount Price'}>
                                            <Input bordered className="ant-input-number " style={{ width: '100%', }} addonBefore="JOD"

                                                defaultValue={this.state.selectedMenu.daysDiscountPrice + ''}
                                                onChange={(e) => {

                                                    var _selected = this.state.selectedMenu;
                                                    _selected.daysDiscountPrice = Number.parseFloat(e.target.value);

                                                    this.setState({ selectedMenu: _selected, })
                                                }}

                                            />
                                        </Form.Item>
                                    )
                                }



                           

                                {
                                    this.state.selectedMenu.childType == 3 && (
                                        <div>

                                            <div
                                                style={{
                                                    margin: '24px 0',
                                                }}
                                            />

                                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                                {/*<span style={{ color: 'red' }}>* </span>*/}
                                                Discount days  </Title>
                                            <Select
                                                mode="multiple"
                                                showArrow
                                                style={{
                                                    width: '100%',
                                                }}


                                                defaultValue={this.state.selectedMenu.daysDiscount ? JSON.parse(this.state.selectedMenu.daysDiscount) : []}


                                                onChange={(value) => {
                                                     
                                                    this.state.selectedMenu.daysDiscount = JSON.stringify(value)
                                                }}
                                                options={[
                                                    {
                                                        'label': 'Friday', 'value': 'Friday'
                                                    }, {
                                                        'label': 'Monday', 'value': 'Monday'
                                                    }, {
                                                        'label': 'Saturday', 'value': 'Saturday'
                                                    }, {
                                                        'label': 'Sunday', 'value': 'Sunday'
                                                    }, {
                                                        'label': 'Thursday', 'value': 'Thursday'
                                                    }, {
                                                        'label': 'Tuesday', 'value': 'Tuesday'
                                                    }, {
                                                        'label': 'Wednesday', 'value': 'Wednesday'
                                                    } 
                                                ]}
                                            />

                                        </div>
                                    )
                                }

                                {
                                    this.state.selectedMenu.childType == 3 && (
                                        <>
                                            <div
                                                style={{
                                                    margin: '24px 0',
                                                }}
                                            />


                                            <Title level={5} style={{ fontSize: 'smaller' }}>
                                             Discount Price Range Time  </Title>


                                            <TimePicker.RangePicker


                                                format="hh:mm A"
                                                value={this.state.selectedMenu.daysDiscountTimes ? (JSON.parse(this.state.selectedMenu.daysDiscountTimes)).map(m => moment(m, 'HH:mm A')) : []}
                                                onChange={(time, timeString) => {

                                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })

                                                    debugger

                                                    var _selected = this.state.selectedMenu;
                                                    _selected.daysDiscountTimes = JSON.stringify(timeString)
                                                    this.setState({ selectedMenu: _selected, })
                                            } } />
                                        </>
                                    )
                                }

                               





                                <div
                                    style={{
                                        margin: '24px 0',
                                    }}
                                />
                                <div>
                                    <Label> Display </Label> 

                                    <Switch
                                        checked={this.state.selectedMenu.visible}
                                        onChange={(checked) => {

                                            var _selected = this.state.selectedMenu;
                                            _selected.visible = checked;

                                            this.state.listMenu.forEach(f => {
                                                if (f.id == _selected.id) {
                                                    f = _selected;
                                                }
                                            })

                                            this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                        }}

                                        style={{ marginTop: 4, float: 'right' }} defaultChecked={false} />
                                </div>

                                {
                                   
                                    this.state.selectedMenu.childType == 3 && (
                                        <div>
                                            <div
                                                style={{
                                                    margin: '24px 0',
                                                }}
                                            />
                                            <Label> Mark as Sold Out </Label>

                                            <Switch
                                                checked={this.state.selectedMenu.soldOut}
                                                onChange={(checked) => {

                                                    var _selected = this.state.selectedMenu;
                                                    _selected.soldOut = checked;

                                                    this.state.listMenu.forEach(f => {
                                                        if (f.id == _selected.id) {
                                                            f = _selected;
                                                        }
                                                    })

                                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                                }}

                                                style={{ marginTop: 4, float: 'right' }} defaultChecked={false} />

                                            </div>
                                    )
                                }
                            </Form>

                        </Tabs.TabPane>


                    </Tabs>
                </Drawer>

                <Modal
                    title={`Delete ${this.state.selectedMenu.name}!`}
                    open={this.state.open}
                    onOk={() => {
                        this.DeleteMenu(this.state.selectedMenu.id)
                    }}
                    onCancel={() => {
                        this.setState({ open: false })
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Confirm Delete ? category will be invisible and deleted!</p>
                </Modal>
            </Spin >
        );
    }
}
