import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

//import './assets/css/bootstrap.min.css'
//import './assets/css/icons.min.css'
//import './assets/css/metisMenu.min.css'
//import './assets/css/app.min.css'
import { MenuIndex } from './screens/menu-management';
import { MainCategories } from './screens/menu-management/main-categories';
import { MenuItems } from './screens/menu-management/menu-items';
import authService from './components/api-authorization/AuthorizeService';
import { Help } from './screens/more-app/Help';
import { Privacy } from './screens/more-app/privacy';
import { Terms } from './screens/more-app/terms';

export default class App extends Component {
    static displayName = App.name;

     
  render () {
    return (
      <Layout>
            <AuthorizeRoute exact path='/' component={MenuIndex} />
            <AuthorizeRoute exact path='/menu-index' component={MenuIndex} />
            <AuthorizeRoute exact path='/help' component={Help} />
            <AuthorizeRoute exact path='/terms' component={Terms} />
            <AuthorizeRoute exact path='/privacy' component={Privacy} />
            
            <AuthorizeRoute exact path='/menu-index/main-categories/:id' component={MainCategories} />
            <AuthorizeRoute exact path='/menu-index/items/:menuId/:categoryId' component={MenuItems} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
