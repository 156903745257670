
import React, {   useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import { Button, Descriptions, PageHeader } from 'antd';

import { 
    MenuFoldOutlined,
    MenuUnfoldOutlined, 
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

const TopBar = ({ isCollapsed, onSideClose}) => {


    //const [collapsed, setCollapsed] = useState(isMobile ? true : false);

    //const toggleCollapsed = () => {
    //    export_collapsed(!collapsed)
    //    setCollapsed(!collapsed);
    //};

    return (
        <div className="site-page-header-ghost-wrapper">
            <PageHeader
                style={{
                    padding: '3px 15px 0 0',
                    boxShadow: 'rgb(240, 240, 240) 0px -1px 0px inset'
                }}
                ghost={false}
                onBack={() => { }}
                backIcon={
                    <Button
                        type="text"
                        onClick={() => {
                            onSideClose(true)
                        }}
                        style={{
                            marginBottom: 16,
                            height : 0
                        }}
                    >
                        {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                }
                subTitle=""
                extra={[
                    <LoginMenu />,
                    //<Button key="2">Operation</Button>,
                    //<Button key="1" type="primary">
                    //    Primary
                    //</Button>,
                ]}
            >
                <Descriptions size="small" column={3}>

                </Descriptions>
            </PageHeader>
        </div>
    );
}

export default TopBar