import { Tabs } from 'antd';
import React, { Component } from 'react';
import { Row, Col } from 'antd';

export class Help extends Component {
    static displayName = Help.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() { 
  }
 

  render() { 

      return (
          <div className="row">

              <div className="col-12">

                  <Tabs defaultActiveKey="1" tabBarStyle={{ padding: '0px 24px', backgroundColor: '#fff' }}>
                      <Tabs.TabPane
                          style={{
                              padding: '1.5rem',
                              width: '100%',
                              height: 'calc(100% - 201px)',

                          }}

                          tab="" key="1">

                          <Row>

                              <Col span={12} offset={6}>
                          <div class=" text-center bg-white p-5 " >
                              <h1 class="pt-5"> CONTACT INFORMATION </h1>

                              <p class="mt-5 "  >


                                  <b class="text-uppercase">Join tarabezah NETWORK? </b>
                                  <br/>
                                      If you are interested in joining the Tarabezah network of restaurants please email us with your contact details and we will contact you within 24 hours&nbsp;
                                      <br/>
                                          <a href="mailto: sales@tarabezah.com">sales@tarabezah.com</a>
                                          <br/>
                                              <br/>

                                                  <b class="text-uppercase"> INTERNSHIP and job  OPPORTUNITIES </b>
                                                  <br/>

                                                      in everything we do and deliver, our main goal is to fulfill our customer's experience regularly with unique services we offer through our outstanding team. if you are creative, dedicated and passionate, join Tarabezah team now by sending your resume to email

                                                      <br/>
                                                          <a href="mailto: employee@tarabezah.com">employee@tarabezah.com</a>

                                                          <br/>
                                                              <br/>
                                                                  <br/>

                                                                      <b class="text-uppercase">  your opinion is valuable to us </b>
                                                                      <br/>

                                                                          We are continually aiming to provide high-quality services to our users. We take complaints very seriously, so if you would like to share your thoughts on how to improve our services please feel free to email us at

                                                                          <a href="mailto: support@tarabezah.com">support@tarabezah.com</a>
                                                                          <br/>
                                                                              <br/>
                                                                                  <br/>

                                                                                      <b class="text-uppercase">  GENERAL INQUIRIES </b>

                                                                                      <br/>
                                                                                          Phone:  <a href="tel: +962 796777688">+962 796777688</a>
                                                                                          <br/>
                                                                                              Email: <a href="mailto: info@tarabezah.com">info@tarabezah.com</a>
                </p>
            </div>
             

                              </Col>
                          </Row>


                      </Tabs.TabPane>
                  </Tabs>


              </div>

      </div>
    );
  }

 
}
