import { Tabs } from 'antd';
import React, { Component } from 'react';
import { Row, Col } from 'antd';

export class Terms extends Component {
    static displayName = Terms.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() { 
  }
 

  render() { 

      return (
          <div className="row">

              <div className="col-12">

                  <Tabs defaultActiveKey="1" tabBarStyle={{ padding: '0px 24px', backgroundColor: '#fff' }}>
                      <Tabs.TabPane
                          style={{
                              padding: '1.5rem',
                              width: '100%',
                              height: 'calc(100% - 201px)',

                          }}

                          tab="" key="1">

                          <Row>

                              <Col span={18} offset={3}>
                          <div class=" text-center bg-white p-5 " >
                              <h1 class="pt-5"> TERMS AND CONDITIONS </h1>


                                      <p class="mt-5 " className="text-justify">
                                          This USER Agreement and the policies referred here  contain the complete terms and conditions that apply to your use of the reservation services being offered at Tarabezah Application. As used in this Agreement, "Tarabezah" refers to the Application , and any of its subsidiaries, and "USER" or "you" refers to you. USE OF THE SERVICES AND TARABEZAH�S CONSTITUTES KNOWING ACCEPTANCE AND ACKNOWLEDGEMENT OF THIS USER AGREEMENT, TARABEZAH'S PRIVACY POLICY.  Tarabezah may modify this Agreement's terms and conditions at any time without notice. Continued use of the Services at Tarabezah after a change in this Agreement, a change in the Privacy Policy, or after implementation of any other new policy constitutes acceptance of such change or policy. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT OR ANY REVISED VERSION OF THIS AGREEMENT, PLEASE DO NOT USE THIS SITE.
                                          1. WHAT WE DO
                                          Tarabezah provides the Services to USER for the purpose of assisting USER in reservations at participating third party vendors including restaurants, cafes and lounges. In response to a USER's online request it directly contacts the Venue's computerized database of reservations. The availability of reservations is determined at the time of the USER's query. Once the USER makes a reservation,Tarabezah will provide confirmation or cancellation  of the reservation to USER as a notification on the mobile application. By using the Services, USER agrees to receive reservation confirmation or cancellation on the application after booking a reservation through Tarabezah.
                                          2. CANCELLATIONS  AND NO -SHOW POLICY
                                          Tarabezah is committed to providing superior quality services to its USERS and Vendors. To assist us in maintaining a consistently high level of service with the participating Vendors ,USERS are asked to cancel any reservations that they will be unable to honor at least 30 minutes in advance on the day of the reservation. You may cancel your reservation online through the application or by calling the Venue directly. If you are unable to keep your reservation and you fail to cancel, Tarabezah will add it in your history ,letting other vendors know that your records indicate that you were a no-show.Your Tarabezah account will be blocked if you did not show up for four  reservations within the same twelve-month period.If you receive a no-show notification email in error, please  for information on how to dispute it.


                                          3.PRIVACY POLICY
                                          Tarabezah is very concerned to safeguarding your privacy online. Please review our privacy policy ,about how we collect, use, and disclose information in connection with the Services.

                                          4. RESERVATION AND USAGE GUIDELINES
                                          The Services are being offered to USER for USER's personal use. USER agrees to use the Services only to book reservations at listed Vendors and then honor those reservations by arriving at the Vendor(s) on time. USER further agrees not to book more than two reservations at the same time and location. All other uses which are unlawful or in violation of this Agreement's terms and conditions are prohibited. Tarabezah has the right, in its sole discretion, to refuse service, block accounts, remove or edit content, or cancel reservations.

                                          5.EMAIL POLICY
                                          You will be receiving a welcoming email from Tarabezah as you sign up, as well as periodic emails from time to time .In accordance with our Privacy Policy, we do not share any of your personal information without your prior consent.

                                          6. EXCLUSIVE PROMOTIONS
                                          By using the mobile applications you agree to notify us immediately of any errors or omission related to the pricing or description of advertised products. We will use reasonable efforts to correct any errors or omissions as soon as practicable after learning of them. But you are responsible for paying any additional charges.

                                          7. INTELLECTUAL PROPERTY AND GRANT OF RIGHTS TO USER
                                          The materials and services provided on Tarabezah are protected by copyright, trademark, patent and other intellectual property laws. All text, graphics, video, data or other content on Tarabezah is provided to USER by Tarabezah for the sole purpose of using the Services. USER shall not copy, display, modify, create derivative works of, publish, or sell Tarabezah Content or any information, software, or services provided by Tarabezah.Tarabezah Content may be modified from time to time by Tarabezah in its sole discretion. No license is granted to USER for any other purpose, and any other use of the Services or Tarabezah Content. USER shall constitute a material breach of this Agreement. Nothing in this Agreement shall affect any rights of Tarabezah or its licensors in the Services or Content, and any associated patents, trademarks, copyrights, mask work rights, trade secrets or other intellectual property rights. No license, right or interest in any trademarks of  Tarabezah or any third party is granted under this Agreement. Nothing in this Agreement shall affect any rights of Tarabezah or its licensors in the Services or  Content, and any associated patents, trademarks, copyrights, mask work rights, trade secrets or other intellectual property rights..

                                          8. REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT
                                          At Tarabezah Application the USER may submit reviews, comments, and ratings,and other communications; so as long as the content is not illegal, threatening, obscene, racist, defamatory, libelous, pornographic, infringing of intellectual property rights, promoting of illegal activity or harm to groups and/or individuals, invasive of privacy, purposely false or otherwise injurious to third parties or objectionable and does not consist of or contain software, computer viruses, commercial solicitation, political campaigning, chain letters, mass mailings, any form of "spam" or references to illegal activity, malpractice, purposeful overcharging, false advertising or health code violations (e.g., foreign objects in food, food poisoning, etc.).Tarabezah has the right to remove or edit such content, but may not regularly review submitted content. The USER should represent that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity. Tarabezah has the right to monitor and edit or remove any activity or content, including but not limited to content that violates the standards of this Application.Tarabezah has the right to remove/edit any submitted review from the customer after checking the validity of the review as the rating and review system  in Tarabezah relies on validity and honesty.

                                          9. ACCESS AND INTERFERENCE
                                          Much of the information on Tarabezah is updated on a real time basis and is proprietary or licensed to Tarabezah by its Vendors or third parties. By using Tarabezah , you agree not to:
                                          -Use any robot, spider, scraper or other automatic device, process or means to access Tarabezah for any purpose without Tarabezah�s express written permission.
                                          -Take any action that imposes or may impose an unreasonable or disproportionately large load on our infrastructure.
                                          -Implement any manual process to monitor or copy Tarabezah�s Content fromTarabezah without express written permission.
                                          -Utilize any device, software or routine that will interfere or attempt to interfere with the functionality of Tarabezah.

                                          10. LIABILITY LIMITATIONS
                                          Tarabezah is neither an agent nor is connected with any affiliated venue in which a user has made a reservation. Aside from the services provided on Tarabezah. Tarabezah is not liable for any injury, loss, claim, damage or any special, exemplary, punitive, incidental or consequential damages of any kind, whether based in contract or otherwise which arises out of or is connected with a user�s visit to an affiliated venue.
                                          11. SECURITY
                                          Tarabezah Application takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However,Tarabezah cannot guarantee the security of any information that is disclosed online.
                                          12. DISCLAIMER OF WARRANTY
                                          The information, programs, products and material contained in or accessed on Tarabezah, including without limitation, the services and the Tarabezah content are provided to the user and without warranty of any kind. Tarabezah disclaims all warranties, expressed or implied with respect to the services.

                                          13. SEVERABILITY
                                          If any of the provisions, or portions thereof, of this Agreement are found to be invalid under any applicable statute or rule of law, then, that provision notwithstanding, this Agreement shall remain in full force and effect and such provision or portion thereof shall be deemed omitted.

                                          14. ASSIGNMENT
                                          This Agreement and the rights granted and obligations undertaken hereunder may not be transferred, assigned or delegated in any manner by USER, but may be so transferred, assigned or delegated by Tarabezah..
                                          15. WAIVER
                                          Any waiver of any provision of this Agreement, or a delay by any party in the enforcement of any right hereunder, shall neither be construed as a continuing waiver nor create an expectation of non-enforcement of that or any other provision or right.

                                          16. GOVERNING LAW AND JURISDICTION
                                          This Agreement is made under and shall be governed by and construed in accordance with the laws of the Jordan, without regard to any choice of law provisions. Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of Jordan.



                                          17. CONTACT US
                                          Tarabezah can be contacted as follows:
                                          Phone: 962-796777688
                                          Email : info@tarabezah.com
                                          Address : Arrar St / Armada tower













                                          The term of use that have been done by the campony:


                                          Terms and Conditions for Tarabezah

                                          Intellectual Property Rights
                                          Other than the content you own, under these Terms, Tarabezah and/or its licensors own all the intellectual property rights and materials contained in this Website.
                                          You are granted limited license only for purposes of viewing the material contained on this Website.

                                          Restrictions
                                          You are specifically restricted from all of the following:

                                          publishing any Website material in any other media;
                                          selling, sublicensing and/or otherwise commercializing any Website material;
                                          publicly performing and/or showing any Website material;
                                          using this Website in any way that is or may be damaging to this Website;
                                          using this Website in any way that impacts user access to this Website;
                                          using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;
                                          engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;
                                          using this Website to engage in any advertising or marketing.
                                          Certain areas of this Website are restricted from being access by you and Tarabezah may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.

                                          Your Content
                                          In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Tarabezah a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.

                                          Your Content must be your own and must not be invading any third-party s rights. Tarabezah reserves the right to remove any of Your Content from this Website at any time without notice.

                                          Your Privacy
                                          Please read Privacy Policy.

                                          No warranties
                                          This Website is provided "as is," with all faults, and Tarabezah express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.

                                          Limitation of liability
                                          In no event shall Tarabezah, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  Tarabezah, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.

                                          Indemnification
                                          You hereby indemnify to the fullest extent Tarabezah from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.

                                          Severability
                                          If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.

                                          Variation of Terms
                                          Tarabezah is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.

                                          Assignment
                                          The Tarabezah is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.

                                          Entire Agreement
                                          These Terms constitute the entire agreement between Tarabezah and you in relation to your use of this Website, and supersede all prior agreements and understandings.

                                          Governing Law &amp; Jurisdiction
                                          These Terms will be governed by and interpreted in accordance with the laws of the State of jo, and you submit to the non-exclusive jurisdiction of the state and federal courts located in jo for the resolution of any disputes.
                                      </p>



            </div>
             

                              </Col>
                          </Row>


                      </Tabs.TabPane>
                  </Tabs>


              </div>

      </div>
    );
  }

 
}
