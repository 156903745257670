import React, { Component, useState } from 'react';
import { Button, Radio, Typography, PageHeader, Space, Select, Tag, Modal, Tabs, Switch, Drawer, Input, Upload, List, Skeleton, message } from 'antd';
import { DeleteOutlined, InboxOutlined, SettingOutlined } from '@ant-design/icons';
import { NavLink, NavItem, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import authService from '../../components/api-authorization/AuthorizeService';
import { isMobile } from 'react-device-detect';

const { TextArea } = Input;
const { Option } = Select;

export class MenuIndex extends Component {

    static displayName = MenuIndex.name;

    constructor(props) {
        super(props);
        this.state = { openMenuEdit: false, txtValue: '', loading: false, error: '', listMenu: [], open: false, selectedMenu: '' };
        this.getPlaceMenus();
    }

    success() {
        message.success('Updated Successfuly');
    }
    error(err) {
        message.error(err ? err : 'Failed Update!');
    }
    warning() {
        message.warning('Oops! something went wrong');
    }

    async createDefault() {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch('PlaceMenu', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'post',
            });
            const result = await response.json();

            if (result) {
                this.setState({ loading: false, listMenu: result });
                this.success()
            }

        } catch {
            this.setState({ loading: false });
            this.error()
        } finally {
            this.setState({ loading: false });
        }

    }

    async getPlaceMenus() {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch('PlaceMenu/GetPlaceMenus', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'get',
            });
            const result = await response.json();

            if (result) {
                this.setState({ loading: false, listMenu: [] });
                this.setState({ loading: false, listMenu: result });
            }

        } catch {
            this.setState({ loading: false });
        } finally {
            this.setState({ loading: false });
        }

    }

    async ChangeMenuStatus(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenu/ChangeMenuStatus/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenus();
                this.success()
            } else {
                message.error('Failed Update! menu with same language already exist!');
            }

        } catch (ex) {
            this.setState({ loading: false });
            this.error()
        } finally {
            this.setState({ loading: false });
        }

    }

    async EditMenu(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenu/EditMenu/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify(this.state.selectedMenu)
            });


            const result = await response.json();
             
            if (result == true) {
                this.setState({ loading: false, });
                this.getPlaceMenus();
                this.success()
                this.setState({ openMenuEdit: false })
            } else {
                this.error(result.error)
            }

        } catch (ex) {
            this.setState({ loading: false });
            this.error()

        } finally {
            this.setState({ loading: false });
        }

    }

    async DeleteMenu(id) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenu/DeleteMenu/${id}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenus();
                this.success()

            }

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()

        } finally {
            this.setState({ loading: false, open: false });
        }

    }


    async DeleteChildImage(url) {
        try {
            this.setState({ loading: true })

            const token = await authService.getAccessToken();
            const response = await fetch(`PlaceMenu/DeleteChildImage`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                method: 'put',
                body: JSON.stringify({ url: url })
            });


            const result = await response.json();

            if (result) {
                this.setState({ loading: false, });
                this.getPlaceMenuCategories();
                this.success()

            }

        } catch (ex) {
            this.setState({ loading: false, open: false });
            this.error()

        } finally {
            this.setState({ loading: false, open: false });
        }

    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }



    render() {



        const { Dragger } = Upload;
        const { Title, Text } = Typography;


        return (
            <div>

                <PageHeader
                    style={{ backgroundColor: '#fff' }}
                    className="site-page-header"
                    title="Menu Management"
                    extra={[
                        <Button onClick={() => this.createDefault()} style={{ borderRadius: '8px' }} type="primary">Create a Menu</Button>
                    ]}
                >
                </PageHeader>

                <Tabs defaultActiveKey="1" tabBarStyle={{ padding: '0px 24px', backgroundColor: '#fff' }}>
                    <Tabs.TabPane
                        style={{
                            padding: '1.5rem',
                            width: '100%',
                            height: 'calc(100% - 201px)'
                        }}

                        tab="Menus" key="1">


                        <List
                            itemLayout={isMobile ? 'vertical' : 'horizontal'}
                            size="default"

                            grid={{ gutter: isMobile ? 0 : 16, column: isMobile ? 1 : 4 }}
                            dataSource={this.state.listMenu}
                            renderItem={(item) => (
                                <List.Item

                                    style={{
                                        padding: '15px',
                                        backgroundColor: '#fff',
                                        borderRadius: '12px',
                                        marginBottom: '15px',
                                        display: 'block'
                                    }}
                                    key={item.title}

                                    actions={
                                        !this.state.loading
                                            ? [
                                                //<Switch checked={item.visible} size="small" style={{}} defaultChecked onChange={(checked) => { this.ChangeMenuStatus(item.id) }} />,
                                                //<Link className=" ant-btn ant-btn-primary ant-btn-sm" style={{ borderRadius: 8, marginInline: '10px' }} to={{
                                                //    pathname: `/menu-index/main-categories`,

                                                //}}>Main Categories</Link>,
                                            ]
                                            : undefined
                                    }
                                    extra={
                                        !this.state.loading && (
                                            <>


                                                <Switch checked={item.visible} size="small" style={{}} defaultChecked onChange={(checked) => { this.ChangeMenuStatus(item.id) }} />

                                                <Link className=" ant-btn ant-btn-primary ant-btn-sm" style={{ borderRadius: 8, marginInline: '10px' }} to={{
                                                    pathname: `/menu-index/main-categories/${item.id}`,

                                                }}>Main Categories</Link>

                                                <SettingOutlined style={{ paddingInlineEnd: '15px' }} onClick={() => {
                                                    this.setState({ selectedMenu: item, openMenuEdit: true })
                                                }} />


                                                <DeleteOutlined style={{ paddingInlineEnd: '15px', color: "red" }} onClick={() => {

                                                    this.setState({ selectedMenu: item, open: true })
                                                }} />
                                            </>
                                        )
                                    }
                                >
                                    <Skeleton loading={this.state.loading} active >
                                        <List.Item.Meta
                                            title={<Title level={5}> {item.name}
                                                {'   '}
                                                <Tag color={item.visible ? 'green' : 'default'} style={{ borderRadius: "8px", borderColor: 'rgb(240, 240, 240)', color: 'rgb(140, 140, 140)' }}>
                                                    {item.visible ? 'Active' : 'Inactive'}
                                                </Tag>
                                            </Title>}
                                            description={<Text type="secondary"> Last updated on {moment(item.updatedDate).format('dddd MMM YYYY hh:mm')}</Text>}
                                        />
                                        {/*{item.content}*/}
                                    </Skeleton>
                                </List.Item>
                            )}
                        />






                    </Tabs.TabPane>
                </Tabs>

                <Drawer
                    title={this.state.selectedMenu.name}
                    placement={'right'}
                    width={500}
                    onClose={() => { this.setState({ openMenuEdit: false, selectedMenu: {} }) }}
                    open={this.state.openMenuEdit}
                    footer={
                        <Space>
                            <Button onClick={() => {
                                this.setState({ openMenuEdit: false })
                            }}>Cancel</Button>
                            <Button type="primary" onClick={() => {

                                if (!this.state.selectedMenu.name) {
                                    message.warning('Name is required');
                                    return false;
                                } else if (!this.state.selectedMenu.menuImageUrl && !this.state.selectedMenu.menuImageUrlNew) {
                                    message.warning('Image is required is required');
                                    return false;
                                } else if (!this.state.selectedMenu.rateImageUrl && !this.state.selectedMenu.rateImageUrlNew) {
                                    message.warning('Rate Image is required is required');
                                    return false;
                                }


                                this.EditMenu(this.state.selectedMenu.id)
                            }}>
                                OK
                            </Button>
                        </Space>
                    }
                >


                    <Tabs defaultActiveKey="0" tabBarStyle={{ padding: '0px 0px', backgroundColor: '#fff' }}>
                        <Tabs.TabPane
                            style={{
                                padding: '0.0rem',
                                width: '100%',
                                height: 'calc(100% - 201px)'
                            }}

                            tab="Overview" key="0">

                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                <span style={{ color: 'red' }}>* </span>
                                Name  </Title>

                            <TextArea
                                value={this.state.selectedMenu.name}
                                onChange={(e) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.name = e.target.value;

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}
                                size='small'
                                autoSize={{
                                    minRows: 1,
                                    maxRows: 1,
                                }}
                            />

                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        margin: '24px 0',*/}
                            {/*    }}*/}
                            {/*/>*/}


                            {/*<Title level={5} style={{ fontSize: 'smaller' }}>*/}
                            {/*    Description  </Title>*/}
                            {/*<TextArea*/}
                            {/*    value={this.state.selectedMenu.description}*/}
                            {/*    onChange={(e) => {*/}

                            {/*        var _selected = this.state.selectedMenu;*/}
                            {/*        _selected.description = e.target.value;*/}

                            {/*        this.state.listMenu.forEach(f => {*/}
                            {/*            if (f.id == _selected.id) {*/}
                            {/*                f = _selected;*/}
                            {/*            }*/}
                            {/*        })*/}

                            {/*        this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })*/}
                            {/*    }}*/}
                            {/*    size='small'*/}
                            {/*    autoSize={{*/}
                            {/*        minRows: 3,*/}
                            {/*        maxRows: 5,*/}
                            {/*    }}*/}
                            {/*/>*/}

                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Sales Tax  </Title>



                            <input
                                value={this.state.selectedMenu.salesTax}
                                onChange={(e) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.salesTax = Number.parseFloat(e.target.value);

                                    debugger

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}

                                className="ant-input ant-input-sm"
                               

                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />

                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Service Tax  </Title>



                            <input
                                value={this.state.selectedMenu.serviceTax}
                                onChange={(e) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.serviceTax = Number.parseFloat(e.target.value);

                                    debugger

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}

                                className="ant-input ant-input-sm"


                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
 

                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />

                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Note  </Title>

                            <TextArea

                                value={this.state.selectedMenu.taxAndServiceMessage}
                                onChange={(e) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.taxAndServiceMessage = e.target.value;

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}
                                size='small'
                                autoSize={{
                                    minRows: 1,
                                    maxRows: 3,
                                }}
                                placeholder="20% VAT included to all prices." />

                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                <span style={{ color: 'red' }}>* </span>
                                Menu Image  </Title>

                            <Dragger

                                fileList={
                                    this.state.selectedMenu.menuImageUrl ?
                                        [{ url: this.state.selectedMenu.menuImageUrl }]
                                        :
                                        undefined
                                }

                                multiple={false}
                                beforeUpload={async file => {
                                    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
                                    if (!isJPG) {
                                        message.error('You can only upload JPG file!');
                                        return Upload.LIST_IGNORE;
                                    }

                                    if (file.size > 1000000) {
                                        message.error('File size must be smaller than 1 mb');
                                        return Upload.LIST_IGNORE;
                                    } else {
                                        var _selected = this.state.selectedMenu;
                                        _selected.menuImageUrl = '';

                                        this.setState({ selectedMenu: _selected })

                                        let _base64 = await this.getBase64(file)
                                        this.state.selectedMenu.menuImageUrlNew = _base64
                                    }
                                    return false;
                                }}
                                listType='picture' maxCount={1}
                                onRemove={(file) => {
                                    var _selected = this.state.selectedMenu;
                                    _selected.menuImageUrl = '';

                                    this.setState({ selectedMenu: _selected })
                                    this.DeleteChildImage(file.url)
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>


                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                <span style={{ color: 'red' }}>* </span>
                                Rate Image  </Title>

                            <Dragger
                                fileList={
                                    this.state.selectedMenu.rateImageUrl ?
                                        [{ url: this.state.selectedMenu.rateImageUrl }]
                                        :
                                        undefined
                                }
                                showPreviewIcon={false}
                                beforeUpload={async file => {
                                    const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
                                    if (!isJPG) {
                                        message.error('You can only upload JPG file!');
                                        return Upload.LIST_IGNORE;
                                    }

                                    if (file.size > 1000000) {
                                        message.error('File size must be smaller than 1 mb');
                                        return Upload.LIST_IGNORE;
                                    } else {
                                        var _selected = this.state.selectedMenu;
                                        _selected.rateImageUrl = '';

                                        this.setState({ selectedMenu: _selected })

                                        let _base64 = await this.getBase64(file)
                                        this.state.selectedMenu.rateImageUrlNew = _base64
                                    }

                                    return false;
                                }}
                                onRemove={(file) => {
                                    var _selected = this.state.selectedMenu;
                                    _selected.rateImageUrl = '';

                                    this.setState({ selectedMenu: _selected })
                                    this.DeleteChildImage(file.url)
                                }}
                                listType='picture' openFileDialogOnClick={true} maxCount={1}  >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>



                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />


                          


                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Display the menu  </Title>


                            <Switch

                                checked={this.state.selectedMenu.visible}
                                onChange={(checked) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.visible = checked;

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}

                                style={{ marginTop: 4 }} defaultChecked={false} />


                            <div
                                style={{
                                    margin: '24px 0',
                                }}
                            />





                            <Title level={5} style={{ fontSize: 'smaller' }}>

                                Is Delivery Menu  </Title>


                            <Switch

                                checked={this.state.selectedMenu.isDeliveryMenu}
                                onChange={(checked) => {

                                    var _selected = this.state.selectedMenu;
                                    _selected.isDeliveryMenu = checked;

                                    this.state.listMenu.forEach(f => {
                                        if (f.id == _selected.id) {
                                            f = _selected;
                                        }
                                    })

                                    this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                }}

                                style={{ marginTop: 4 }} defaultChecked={false} />

                        </Tabs.TabPane>

                        <Tabs.TabPane
                            style={{
                                width: '100%',
                                height: 'calc(100% - 201px)'
                            }}

                            tab="Customize View" key="1">

                            <Table>

                                {/*<tr>*/}
                                {/*    <td style={{ borderTop: 0 }}>*/}
                                {/*        <Title level={5} style={{ fontSize: 'smaller' }}>*/}
                                {/*            Number of Columns :*/}
                                {/*        </Title>*/}
                                {/*    </td>*/}
                                {/*    <td style={{ borderTop: 0 }}>*/}
                                {/*        <Radio.Group value='1' onChange={e => {*/}
                                {/*            //setSize(e.target.value)*/}
                                {/*        }}>*/}
                                {/*            <Radio.Button value="1">1</Radio.Button>*/}
                                {/*            <Radio.Button value="2">2</Radio.Button>*/}
                                {/*            <Radio.Button value="3">3</Radio.Button>*/}
                                {/*            <Radio.Button value="4">4</Radio.Button>*/}
                                {/*        </Radio.Group>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td style={{ borderTop: 0 }}>*/}
                                {/*        <Title level={5} style={{ fontSize: 'smaller' }}>*/}
                                {/*            Grid Title Position :*/}
                                {/*        </Title>*/}
                                {/*    </td>*/}
                                {/*    <td style={{ borderTop: 0 }}>*/}
                                {/*        <Radio.Group value='Top' onChange={e => {*/}
                                {/*            //setSize(e.target.value)*/}
                                {/*        }}>*/}
                                {/*            <Radio.Button value="Top">Top</Radio.Button>*/}
                                {/*            <Radio.Button value="Bottom">Bottom</Radio.Button>*/}
                                {/*        </Radio.Group>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td style={{ borderTop: 0 }}>
                                        <Title level={5} style={{ fontSize: 'smaller' }}>
                                            Language :
                                        </Title>
                                    </td>
                                    <td style={{ borderTop: 0 }}>

                                        <Select
                                            defaultValue={this.state.selectedMenu.languageStr}
                                            style={{
                                                width: 120,
                                            }}
                                            onChange={(e) => {

                                                var _selected = this.state.selectedMenu;
                                                _selected.language = Number.parseInt(e);

                                                this.state.listMenu.forEach(f => {
                                                    if (f.id == _selected.id) {
                                                        f = _selected;
                                                    }
                                                })

                                                this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                            }}
                                        >
                                            <Option value="0">Arabic</Option>
                                            <Option value="1">English</Option>
                                        </Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ borderTop: 0 }}>
                                        <Title level={5} style={{ fontSize: 'smaller' }}>
                                            Currency :
                                        </Title>
                                    </td>
                                    <td style={{ borderTop: 0 }}>

                                        <Select

                                            defaultValue={this.state.selectedMenu.currencyStr}
                                            style={{
                                                width: 120,
                                            }}
                                            onChange={(e) => { 
                                                var _selected = this.state.selectedMenu;
                                                _selected.currency = Number.parseInt(e);

                                                this.state.listMenu.forEach(f => {
                                                    if (f.id == _selected.id) {
                                                        f = _selected;
                                                    }
                                                })

                                                this.setState({ selectedMenu: _selected, listMenu: this.state.listMenu })
                                            }}
                                        >
                                            <Option value="0">JOD</Option>
                                            <Option value="1">USD</Option>
                                            <Option value="2">EGP</Option>
                                        </Select>
                                    </td>
                                </tr>
                            </Table>




                        </Tabs.TabPane>
                    </Tabs>
                </Drawer>

                <Modal
                    title="Delete Menu!"
                    open={this.state.open}
                    onOk={() => {
                        this.DeleteMenu(this.state.selectedMenu.id)
                    }}
                    onCancel={() => {
                        this.setState({ open: false })
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Confirm Delete ? menu will be invisible and deleted!</p>
                </Modal>
            </div>
        );
    }
}
