import React, { useState } from 'react'
import MetisMenu from '@metismenu/react';
import 'metismenujs/dist/metismenujs.css';
import {
    AppstoreOutlined,
    ContainerOutlined,
    DesktopOutlined,
    MailOutlined,
    MenuFoldOutlined,
    ant,
} from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Link, NavLink, useHistory } from "react-router-dom";
import { LoginMenu } from './api-authorization/LoginMenu';
import { NavItem } from 'reactstrap';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    getItem('Menu Management', '1', <span role="img" className="anticon ant-menu-item-icon"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z"></path></svg></span>),
    getItem('Help', '2', <span role="img" className="anticon ant-menu-item-icon"> </span>),
    getItem('Terms', '3', <span role="img" className="anticon ant-menu-item-icon"> </span>),
    getItem('Privacy', '4', <span role="img" className="anticon ant-menu-item-icon"> </span>),

];
function selected() {

    if (window.location.href.indexOf('menu-index') > -1) {
        return ['1']
    } else if (window.location.href.indexOf('help') > -1) {
        return ['2']
    } else if (window.location.href.indexOf('terms') > -1) {
        return ['3']
    } else if (window.location.href.indexOf('privacy') > -1) {
        return ['4']
    } else {
      return   ['1']
    }

}

const SidebarNav = ({ isCollapsed, onSideClose, props }) => {
    const history = useHistory();
   
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

    return (
        <div >

            <BrowserView>
                <aside
                    className="ant-layout-sider ant-layout-sider-light  "
                    style={{
                        maxWidth: 200,
                    }}
                >
                    <Menu 
                        mode="inline"
                        theme="light"
                        inlineCollapsed={isCollapsed}
                        items={items}
                        selectedKeys={selected()}
                        onClick={(e) => {
                            if (e.key == 1) {
                                window.location = window.location.origin + '/menu-index';
                            } else if (e.key == 2) {
                                window.location = window.location.origin + '/help';

                            } else if (e.key == 3) {
                                window.location = window.location.origin + '/terms';

                            } else if (e.key == 4) {
                                window.location = window.location.origin + '/privacy';

                            }
                        }}
                    />

                </aside>

                <NavItem

                    style={{
                        listStyle: 'none',
                        paddingInlineStart: '33px',
                        paddingBottom: '16px',
                        position: 'fixed',
                        bottom: 0,
                        left: 0
                    }}
                >
                    <NavLink tag={Link} id="login_btn" className="text-dark ant-btn ant-btn-default" to={logoutPath}>Logout</NavLink>
                </NavItem>
            </BrowserView>


            <MobileView>

                <Drawer
                    title="Basic Drawer"
                    placement={'left'}
                    closable={true}

                    onClose={() => {
                        isCollapsed = false;
                        onSideClose(true)
                    }}
                    open={!isCollapsed}
                    key={'left'}
                    size='large'
                >
                    <Menu
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        theme="ligh"
                        inlineCollapsed={false}
                        items={items}
                        onClick={(e) => {
                            if (e.key == 1) {
                                window.location.replace('menu-index')
                            }
                        }}
                    />


                    <NavItem

                        style={{
                            listStyle: 'none',
                            paddingInlineStart: '33px',
                            paddingBottom: '16px',
                            position: 'absolute',
                            bottom: 0,
                            left: 0
                        }}
                    >
                        <NavLink tag={Link} className="text-dark ant-btn ant-btn-default" to={logoutPath}>Logout</NavLink>
                    </NavItem>
                </Drawer>


            </MobileView>
        </div>


    );
}

export default SidebarNav